import React from "react";
import { useFormikContext } from "formik";
import { Button } from "@mui/material";

const ButtonWrapper = ({ children, ...otherProps }: any) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const configButton = {
    ...otherProps,
    variant: "contained",
    color: "primary",
    fullWidth: true,
    onClick: handleSubmit,
  };

  return (
    <Button
      style={{
        borderRadius: 50,
        padding: "12px 30px",
      }}
      disabled={true}
      {...configButton}
    >
      {children}
    </Button>
  );
};

export default ButtonWrapper;
