import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import StarIcon from "@mui/icons-material/Star";

const RadioButtonWrapper = () => {
  const t = useTranslation();

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  return (
    <div>
      <Box
        display="flex"
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent="center"
        gap={2}
        alignItems={"center"}
        justifyItems={"center"}
        my={6}
      >
        <Button onClick={() => setActiveIndex(0)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 230,
              height: 120,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                activeIndex === 0 ? "#e8dcfd" : "rgba(0, 0, 0, 0)",
              border:
                activeIndex === 0 ? "2px solid #7e57c2" : "2px solid #c4b2e3",
              "&:hover": {
                border: "2px solid #7e57c2",
                backgroundColor: "#e8dcfd",
              },
            }}
          >
            <MonetizationOnIcon fontSize="large" />
            <Typography color="black">
              {t("application.userNeedPrice")}
            </Typography>
          </Box>
        </Button>

        <Button onClick={() => setActiveIndex(1)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 230,
              height: 120,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:
                activeIndex === 1 ? "#e8dcfd" : "rgba(0, 0, 0, 0)",
              border:
                activeIndex === 1 ? "2px solid #7e57c2" : "2px solid #c4b2e3",
              "&:hover": {
                border: "2px solid #7e57c2",
                backgroundColor: "#e8dcfd",
              },
            }}
          >
            <StarIcon fontSize="large" />
            <Typography color="black">
              {t("application.userNeedService")}
            </Typography>
          </Box>
        </Button>
      </Box>
    </div>
  );
};

export default RadioButtonWrapper;
