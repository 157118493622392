import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';

const DateTimePickerWrapper = ({name, ...otherProps}: any) => {
    const [field, meta] = useField(name);

    const configDateTimePicker = {
        ...otherProps,
        ...field,
        type: 'date',
        variant: 'outlined',
        fullWidth: true,
        InputLabelProps: {
            shrink: true,
        },
        color: 'primary'
    };

    if (meta && meta.touched && meta.error) {
        configDateTimePicker.error = true;
        configDateTimePicker.helperText = meta.error;
    }

    return (
        <TextField required {...configDateTimePicker}></TextField>
    );
};

export default DateTimePickerWrapper;
