import React from "react";
import CreateTask from "../components/tasks/CreateTask";
import TaskList from "../components/tasks/TaskList";
import FilterSection from "../components/tasks/FilterSection";
import CreateTaskForm from "../components/CreateTaskForm";
import TrainingParent from "../components/trainingParent";
import ImageSlider from "../components/ImageSlider";

const Dashboard = () => {
  return (
    <>
      {/*<ImageSlider />*/}
      {/*<TrainingParent/>*/}
      {/*<CreateTaskForm />*/}
      <CreateTask />
      <FilterSection />
      <TaskList />
    </>
  );
};

export default Dashboard;
