export const StatusOptions = {
  All: "all",
  Active: "active",
  Completed: "completed",
};

export const priorityOptions = ['green', 'blue', 'orange', 'purple', 'red']


/* state */
const initialState = {
  status: StatusOptions.All,
  priorities: [],
};

export default function tasksFilterReducer(state = initialState, action: any) {

  console.log("output: ", action.payload);

  switch (action.type) {
    case "tasks/filterByStatus": {
      return {
        ...state,
        status: action.payload,
      };
    }

    case "tasks/filterByPriority": {
      let { priority, changeType } = action.payload; // extract the payload
      console.log("tasks/filterByPriority: ", action.payload);
      const { priorities } = state; // extract from the state

      // based on the changeType - Either add or remove
      switch (changeType) {
        case "added": {
          console.log("added");
          // @ts-ignore
          if (priorities.includes(priority)) {
            return state;
          }

          return {
            ...state,
            priorities: state.priorities.concat(priority), // add priority to the store
          };
        }
        case "removed": {
          return {
            ...state,
            priorities: state.priorities.filter(
              (existingPriority) => existingPriority !== priority
            ),
          };
        }
        default:
          return state;
      }
    }

    default:
      return state;
  }
}
