import React from 'react';
import { useField } from 'formik';
import { TextField, useTheme } from '@mui/material';

const TextFieldWrapper = ({ name, ...otherProps }: any) => {
  const [field, meta] = useField(name);
  const theme = useTheme();


  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    color: 'primary',
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return <TextField required {...configTextField} />;
};

export default TextFieldWrapper;
