import { createSelector } from 'reselect';
import { RootState } from '../store';
import { StatusOptions } from './tasksFilterReducer';
import axios from 'axios';

export interface ITask {
  id?: number;
  text?: string;
  completed?: boolean;
  priority: string;
}

const initialState: ITask[] = [
  { id: 0, text: "Learn React", completed: true, priority: "green" },
  { id: 1, text: "Learn Redux", completed: false, priority: "blue" },
  { id: 2, text: "Build something fun!", completed: false, priority: "red" },
];

/**
 * Generates a new incremented taskId
 * @param task
 */
function nextTaskId(task: any) {
  const maxId = task.reduce(
    (maxId: any, task: any) => Math.max(task.id, maxId),
    -1
  );
  return maxId + 1;
}

/**
 * Accepts one or more *input selectors and *outputs a new selector
 * get both states as a streams and handle them further
 */
export const selectFilteredTasks = createSelector(
    (state: RootState) => state.tasksReducer,
    (state: RootState) => state.taskFilterReducer,
    (tasks, filters) => {

      // extract states from FilterReducer
      const { status, priorities } = filters;

      // check if the status is the default one
      const showAll: boolean = status === StatusOptions.All;

      // if the status is All (default) and no priority is selected, return the current state
      if (showAll && priorities.length === 0) {
        return tasks;
      }

      // if status is set to completed
      const showCompleted = status === StatusOptions.Completed;

      return tasks.filter((task: ITask) => {

        const statusMatches = showAll || task.completed === showCompleted;
            (showCompleted as boolean);

        // @ts-ignore
        const priorityMatches = priorities.length === 0 || priorities.includes(task.priority);
        return statusMatches && priorityMatches;
      });
    }
)

export default function tasksReducer(
  state: ITask[] = initialState,
  action: any
) {


  switch (action.type) {
    case "tasks/getTasks": {
      return state;
    }
    case "tasks/addTask": {
      return [
        ...state,
        {
          id: nextTaskId(state),
          text: action.payload,
          completed: false,
          color: "red"
        },
      ];
    }

    case "tasks/taskColorSelected": {
      const { id, priority } = action.payload;
      return state.map((task: ITask) => {

        // if no such a id, send back the whole state
        if (task.id !== id) {
          return task;
        }

        // if it's the same id as it exist in state change the property field
        return {
          ...task,
          priority
        }
      });
    }

    case "tasks/allCompleted": {
      return state.map((task: ITask) => {
        return { ...task, completed: true }; // set property to true
      });
    }

    case "tasks/allUncompleted": {
      return state.map((task: ITask) => {
        return { ...task, completed: false }; // set property to false
      });
    }

    case "task/toggled": {
      return state.map((task: ITask) => {
        if (task.id !== action.payload) {
          return task; // display all todos
        }

        return {
          ...task,
          completed: !task.completed, // toggle the completed property
        };
      });
    }

    case "tasks/onClearCompletedClicked": {
      return state.filter((todo: ITask) => !todo.completed);
    }

    case "tasks/tasksLoaded": {
      return action.payload;
    }

    case "tasks/createTask": {
      // return new state Array[]
      return [...state, action.payload];
    }

    default:
      return state;
  }
}

export async function fetchTasks(dispatch: any, getState: any) {
  const url = 'https://jsonplaceholder.typicode.com/posts'

  const response = await axios.get(url);

  const stateBefore = getState();
  console.log("stateBefore: ", stateBefore.tasksReducer.length);

  dispatch({type: 'tasks/tasksLoaded', payload: response.data})

  const stateAfter = getState();
  console.log("stateAfter: ", stateAfter.tasksReducer.length);
}

export function saveNewTask(text: string) {
  const url = 'https://jsonplaceholder.typicode.com/posts'

  return async function saveNewTaskThunk(dispatch: any, getState: any) {
    const initialTask = { text };
    const response = await axios.post(url, { task: initialTask });


    dispatch({type: 'tasks/createTask', payload: response.data})

  }
}
