import React from "react";
import { ITask } from "../../redux/reducer/tasksReducer";
import { useDispatch } from "react-redux";
import { priorityOptions } from '../../redux/reducer/tasksFilterReducer';

type TaskListItemProps = {
  task: ITask;
};



const TaskListItem = ({ task }: TaskListItemProps): JSX.Element => {
  const dispatch = useDispatch();

  const { text, completed, priority, id } = task;

  /**
   * Make the first letter capital
   * @param s
   */
  const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

  const handleColorChange = (e: any) => {
    const priority = e.target.value;
    dispatch({type: 'tasks/taskColorSelected', payload: { id, priority}})
  }

  const priorityOptionsList: JSX.Element[] = priorityOptions.map((color: string) => (
      <option key={color} value={color}>
        {capitalize(color)}
      </option>
  ));

  const handleCompletedChanged = () => {
    dispatch({ type: "task/toggled", payload: task.id });
  };

  return (
      <>
        <div style={{ backgroundColor: "lightgray", border: "1px solid black", display: 'flex' }}>
          <div>{text}</div>
          <div >{priority}</div>
          <div>{completed}</div>
          <input
              type="checkbox"
              checked={completed}
              onChange={handleCompletedChanged}
          />
          <select value={priority} style={{ backgroundColor: priority }} onChange={handleColorChange}>
            <option value=""></option>
            {priorityOptionsList}
          </select>
        </div>
      </>
  );
};

export default TaskListItem;
