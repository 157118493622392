import React from "react";
import { useDispatch } from "react-redux";
import {
  priorityOptions,
  StatusOptions,
} from "../../redux/reducer/tasksFilterReducer";
import { useAppSelector } from "../../redux/hook";
import { RootState } from "../../redux/store";
import { ITask } from "../../redux/reducer/tasksReducer";

/**
 * Renders dynamically the buttons for filter Status "all", "active", "completed"
 * @param status
 * @param onChange
 * @constructor
 */
// @ts-ignore
const FilterStatus = ({ value: status, onChange }) => {
  // ['All', 'Active', 'Completed']
  const renderFilters = Object.keys(StatusOptions).map((key: string) => {
    console.log("key: ", Object.keys(StatusOptions));
    // @ts-ignore
    const value = StatusOptions[key];
    console.log("value: ", value);
    const handleClick = () => onChange(value);
    const className = value === status ? "selected" : "";

    return (
      <li key={value}>
        <button className={className} onClick={handleClick}>
          {key}
        </button>
      </li>
    );
  });

  // useAppSelector is an alternative for useSelector hook
  const tasks = useAppSelector((state: RootState) => state.tasksReducer);

  const numberOfTasksLeft = tasks.filter((task: ITask) => !task.completed)
    .length;

  return (
    <div>
      <h5 style={{ color: 'red' }}>Filter by Status</h5>
        <ul>{renderFilters}</ul>
        <h4>Tasks left: {numberOfTasksLeft}</h4>
    </div>
  );
};

// @ts-ignore
const FilterPriority = ({ value: priorities, onChange }) => {
  /**
   * Loop over priorities and renders them separately
   */
  // ['green', 'blue', 'orange', 'purple', 'red']
  const renderPriorities = priorityOptions.map(
    (priority: string, index: number) => {
      const checked = priorities.includes(priority);
      const handleChange = () => {
        const changeType = checked ? "removed" : "added"; // if found, is checked
        onChange(priority, changeType); // send priority type from the array, and the checked type (whether to be checked or not)
      };

      return (
        <>
          <div key={index}>
            <input
              type="checkbox"
              name={priority}
              checked={checked}
              onChange={handleChange}
            />
            <span style={{ backgroundColor: priority }}>{priority}</span>
          </div>
        </>
      );
    }
  );

  return (
    <div>
      <h5 style={{ color: 'red' }}>Filter by priority: </h5>
      <form>{renderPriorities}</form>
    </div>
  );
};

const FilterSection = () => {
  const dispatch = useDispatch();
  const onMarkCompletedClicked = () => dispatch({ type: "tasks/allCompleted" });

    const onMarkUncompletedClicked = () => dispatch({ type: "tasks/allUncompleted" });

  const onClearCompletedClicked = () =>
    dispatch({ type: "tasks/onClearCompletedClicked" });

  const { status, priorities } = useAppSelector(
    (state: RootState) => state.taskFilterReducer
  );

  const onStatusChange = (status: string) =>
    dispatch({ type: "tasks/filterByStatus", payload: status });

  const onPriorityChange = (priority: string, changeType: string) =>
    dispatch({
      type: "tasks/filterByPriority",
      payload: { priority, changeType },
    });
  return (
    <>
      <FilterPriority value={priorities} onChange={onPriorityChange} />
      <FilterStatus value={status} onChange={onStatusChange} />
      <button onClick={onMarkCompletedClicked}>Mark all completed</button>
      <button onClick={onMarkUncompletedClicked}>Mark all uncompleted</button>
      <button onClick={onClearCompletedClicked}>Hide all completed</button>
    </>
  );
};

export default FilterSection;
