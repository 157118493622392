import React from "react";
import TaskListItem from "./TaskListItem";
import { ITask, selectFilteredTasks } from "../../redux/reducer/tasksReducer";
import { useAppSelector } from "../../redux/hook";
import { createSelector } from "reselect";

export const filteredTasks = createSelector(
  selectFilteredTasks,
  (filteredTasksNew) => filteredTasksNew.map((task: any | ITask) => task)
);

const TaskList = () => {
  // The createSelector is used/read as a normal useSelector from the state
  const tasks = useAppSelector(filteredTasks);

  const renderListItems = tasks?.map((task: ITask) => {
    return <TaskListItem key={task.id} task={task} />;
  });

  return <>{renderListItems}</>;
};

export default TaskList;
