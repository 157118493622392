import React from "react";
import {
  Alert,
  Container,
  Grid,
  Hidden,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../FormsUI/TextFieldWrapper";
import SelectWrapper from "../FormsUI/SelectWrapper";
import countries from "../data/countires.json";
import DateTimePickerWrapper from "../FormsUI/DateTimePickerWrapper";
import { CircularProgress } from "@material-ui/core";
import ButtonWrapper from "../FormsUI/ButtonWrapper";
import { FormikHelpers } from "formik-stepper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import home from "../assets/images/home.svg";
import { useTranslation } from "react-multi-lang";
import RadioButtonWrapper from "../FormsUI/RadioButtonWrapper";

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "",
  dateFrom: "",
  dateTo: "",
  message: "",
};

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),

  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string().required("Required"),

  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  dateFrom: Yup.string().required("Required"),
  dateTo: Yup.string().required("Required"),
  message: Yup.string(),
});

const Application = () => {
  const t = useTranslation();

  const handleSubmit = (values: FormikValues, actions: FormikHelpers<any>) => {
    console.log("form values: ", values);
    setTimeout(() => {
      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus({
        sent: "true",
        message: "Your message has been successfully sent.",
      });
    }, 2000);
  };

  return (
    <Grid container sx={{ margin: {xs: '20px auto', md: '120px auto' } }}>
      <Grid item xs={12} justifyContent="center">
        <Container maxWidth="md">
          <Formik
            initialValues={{ ...INITIAL_FORM_STATE }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleChange,
              isValid,
              isSubmitting,
              values,
              handleReset,
              status,
              dirty,
            }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    border: { xs: "none", md: 1 },
                    borderColor: "primary.main",
                    padding: { xs: 1, md: 6 },
                    borderRadius: 4,
                  }}
                >
                  <Grid item xs={12}>
                    <Stack
                      display="flex"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      mb={4}
                    >
                      <Typography variant="h2" textAlign="center">
                        {t("application.subHeading")}
                      </Typography>
                      <Typography variant="h4" textAlign="center">
                        {t("application.heading")}
                      </Typography>
                    </Stack>

                    <img src={home} alt={home} style={{ flex: 1 }} />

                    <Hidden mdDown>
                      <Typography align="center" mt={3} color="#8c8c8c">
                        {" "}
                        {t("application.paragraph")}
                      </Typography>
                    </Hidden>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      mt={5}
                      mb={1}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <AccountCircleIcon fontSize="large" color="primary" />
                      <Typography variant="h4">
                        {t("application.sectionYourDetails")}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <TextFieldWrapper
                      name="firstName"
                      label={t("application.firstName")}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <TextFieldWrapper
                      name="lastName"
                      label={t("application.lastName")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldWrapper
                      name="email"
                      label={t("application.email")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldWrapper
                      name="phone"
                      label={t("application.phone")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      mt={5}
                      mb={1}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <PlaceIcon fontSize="large" color="primary" />
                      <Typography variant="h4">
                        {t("application.sectionAddress")}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldWrapper
                      name="addressLine1"
                      label={t("application.addressLine1")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldWrapper
                      name="addressLine2"
                      label={t("application.addressLine2")}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <TextFieldWrapper
                      name="city"
                      label={t("application.city")}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <TextFieldWrapper
                      name="state"
                      label={t("application.state")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SelectWrapper
                      name="country"
                      label={t("application.country")}
                      options={countries}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      mt={5}
                      mb={1}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <CalendarMonthIcon fontSize="large" color="primary" />
                      <Typography variant="h4">
                        {t("application.sectionHeading")}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DateTimePickerWrapper
                      name="dateFrom"
                      label={t("application.beginDate")}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DateTimePickerWrapper
                      name="dateTo"
                      label={t("application.endDate")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack
                      mt={5}
                      direction="column"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography variant="h4">
                        {t("application.userNeed")}
                      </Typography>
                      <RadioButtonWrapper></RadioButtonWrapper>
                    </Stack>
                  </Grid>

                  {status ? (
                    <Grid item xs={12}>
                      <Alert severity="success">{status.message}</Alert>
                    </Grid>
                  ) : (
                    <ButtonWrapper
                      type="submit"
                      variant="contained"
                      disabled={!isValid || isSubmitting || !dirty}
                      sx={{ my: 9 }}
                    >
                      {!isSubmitting && t("application.send")}
                      {isSubmitting && <CircularProgress color="primary" />}
                    </ButtonWrapper>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Application;
